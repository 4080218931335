<template>
  <div>
    <div id="superset-container"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const SUPERSET_URL = 'https://37.60.245.72.nip.io'

export default {
  name: 'Dashboard',

  props: {
    companyId: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    servicePointId: {
      required: false,
      default: null,
    },
    isFiltersExpanded: {
      required: false,
      default: true,
    },
    dashboardData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data: () => ({
    dashboard: null,
    sdkScript: null,
  }),

  computed: {
    ...mapState('dashboard', ['supersetGuestToken']),
  },

  mounted() {
    this.initialize()
  },

  beforeDestroy() {
    this.unmountSDK()
  },

  methods: {
    ...mapActions('dashboard', ['loadSupersetGuestToken']),

    initialize() {
      this.loadDashboard()
    },
    loadDashboard() {
      if (!window.supersetEmbeddedSdk) {
        const script = document.createElement('script')
        script.src = 'https://unpkg.com/@superset-ui/embedded-sdk'
        script.onload = () => {
          this.initializeDashboard()
        }
        document.head.appendChild(script)
        this.sdkScript = script
      } else {
        this.initializeDashboard()
      }
    },
    initializeDashboard() {
      try {
        this.dashboard = window.supersetEmbeddedSdk.embedDashboard({
          id: this.dashboardData.uid,
          supersetDomain: SUPERSET_URL,
          mountPoint: document.getElementById('superset-container'),
          fetchGuestToken: () => this.fetchGuestTokenFromBackend(),
          dashboardUiConfig: {
            hideTitle: false,
            filters: {
              expanded: this.isFiltersExpanded,
            },
          },
          debug: false,
        })
      } catch (error) {
        console.error('Error loading Superset dashboard:', error)
      }
    },
    async fetchGuestTokenFromBackend() {
      if (!window.supersetEmbeddedSdk) {
        console.info('fetchGuestTokenFromBackend stopped')
        return null
      }
      console.info('fetchGuestTokenFromBackend')
      const payload = {
        companyId: this.companyId,
        dashboardId: this.dashboardId,
        params: {
          service_point_id: this.servicePointId,
        },
      }
      await this.loadSupersetGuestToken(payload)
      return this.supersetGuestToken
    },
    unmountSDK() {
      if (this.dashboard) {
        this.dashboard.then((res) => {
          res.unmount()
          console.info('unmount Superset')
        })
      }
      this.dashboard = null
      delete window.supersetEmbeddedSdk
      if (this.sdkScript) {
        document.head.removeChild(this.sdkScript)
      }
    },
  },
}
</script>

<style lang="scss">
#superset-container {
  iframe {
    width: 100%;
    height: calc(100vh - (64px + 50px + 24px));
    border: none;
  }
}
</style>
